<template>
	<div class="safetyTraining_page">
		<BannerImg :bannerImgUrl="formData.bannerImgUrl"></BannerImg>
		<div class="module1">
			<div class="content">
				<CourseTitle :title="formData.whyStudyName" contText=""> </CourseTitle>
				<div class="carousel">
					<el-carousel :interval="3000">
						<el-carousel-item
							v-for="(item, index) in formData.whyStudyImgUrl"
							:key="index"
						>
							<div class="text">
								{{formData.whyStudyIntroduce}}
							</div>
							<img class="carousel_img" :src="item" alt="" />
						</el-carousel-item>
					</el-carousel>
				</div>
			</div>
		</div>
		<div class="module2">
			<div class="content">
				<CourseTitle :title="formData.securityIncidentName" contText="">
				</CourseTitle>
				<div class="navBar display">
					<div
						class="navBar_list display pointer"
						:class="navBarIndex == index ? 'navBarActive' : ''"
						v-for="(item, index) in formData.safetySecurityIncidentTypeVOS"
						:key="index"
						@click="changeBtn(item, index)"
					>
						{{ item.typeName }}
					</div>
				</div>
				<div class="safetyAccidents flex flex-wrap">
					<div
						class="safetyAccidents_list"
						v-for="(item, index) in safetyAccidents"
						:key="index"
					>
						<img
							class="safetyAccidents_list_img"
							:src="item.securityIncidentImgUrl"
							alt=""
						/>
						<div class="name display">{{ item.securityIncidentName }}</div>
					</div>
				</div>
			</div>
		</div>
		<div class="module3">
			<div class="content">
				<CourseTitle :title="formData.safetyTrainingName" contText="">
				</CourseTitle>
				<div class="safety_training flex">
					<div class="safety_training_type">
						<div
							class="list display pointer"
							:class="safetyTrainingIndex == index ? 'active' : ''"
							@click="changeType(item, index)"
							v-for="(item, index) in formData.safetySafetyTrainingVOS"
							:key="index"
						>
							<div class="typeName" v-html="item.label"></div>
						</div>
					</div>
					<div class="safety_training_cont">
						<div class="navType flex">
							<!-- <div class="list display pointer nowrap" :class="navTypeIndex == index?'navTypeActive':''" v-for="(item,index) in safetyTrainingList" :key="index" @click="navTypeBtn(item,index)">{{item.label}}</div> -->

							<swiper ref="mySwiper" :options="swiperOptions">
								<swiper-slide
									v-for="(item, index) in safetyTrainingList"
									:key="index"
								>
									<div class="navBarBox flex-center sidebar">
										<div
											class="list display pointer nowrap"
											:class="navTypeIndex == index ? 'navTypeActive' : ''"
											@click="navTypeBtn(item, index)"
										>
											{{ item.label }}
										</div>
									</div>
								</swiper-slide>
							</swiper>
						</div>
						<div class="carousel_box">
							<el-carousel :interval="3000" indicator-position="none">
								<el-carousel-item
									v-for="(item, index) in swiperList"
									:key="index"
								>
									<img class="carousel_img pointer" :src="item" alt="" />
								</el-carousel-item>
							</el-carousel>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="module5">
			<div class="content">
				<CourseTitle :title="formData.serviceProcessName" contText="">
				</CourseTitle>

				<div class="service_process Around">
					<div
						class="process_list display column"
						v-for="(item, index) in formData.safetyServiceProcessVOS"
						:key="index"
					>
						<img class="process_list_img" :src="item.iconUrl" alt="" />
						<div class="process_list_name">{{ item.serviceProcessName }}</div>
						<div class="process_list_introduce">
							{{ item.serviceProcessIntroduce }}
						</div>
						<img
							class="process_list_jt"
							src="@/assets/img/safety/jt.png"
							alt=""
						/>
					</div>
				</div>
			</div>
		</div>
		<div class="module6">
			<CoreCourses
				:coreCourseTitle="formData.coreCourseTitle"
				:registrationCoreCourse="formData.safetyCoreCourseVOS"
				:coreCourses="formData.safetyCoreCourseVOS[0]"
			></CoreCourses>
		</div>
		<div class="module7">
			<div>
				<CourseTitle
					:title="formData.teachingTeamName"
					:contText="formData.teachingTeamIntroduce"
				>
				</CourseTitle>
				<Lecturer :lecturerList="formData.teacherVOS"></Lecturer>
			</div>
		</div>
		<div class="module8">
			<CourseTitle :title="formData.securityRiskName" contText="">
			</CourseTitle>
			<div class="content display">
				<img class="icon_bg" :src="formData.securityRiskImgUrl" alt="" />
			</div>
		</div>
		<div class="module9">
			<div class="">
				<CourseTitle :title="formData.trainingSceneName" contText="">
				</CourseTitle>
				<div class="carousel_box">
					<el-carousel :interval="5000" :autoplay="false">
						<el-carousel-item
							v-for="(item, index) in formData.safetyTrainingSceneVOS"
							:key="index"
						>
							<div class="el_carouse_list flex">
								<img
									class="scenario_img"
									:src="item.trainingSceneImgUrl"
									alt=""
								/>
								<div class="scenario_name display">
									{{ item.trainingSceneName }}
								</div>
							</div>
						</el-carousel-item>
					</el-carousel>
				</div>
			</div>
		</div>
		<div class="module10">
			<div class="content">
				<CourseTitle :title="formData.customerServiceName" contText="">
				</CourseTitle>
				<div class="carousel_box">
					<el-carousel :interval="5000" :autoplay="false">
						<el-carousel-item
							v-for="(list, index) in formData.safetyCustomerServiceVOS"
							:key="index"
						>
							<div class="serve_customer flex">
								<div
									class="serve_customer_list"
									v-for="(item, index2) in list"
									:key="index2"
								>
									<img :src="item.customerServiceImgUrl" alt="" />
									<div class="serve_customer_name display">
										{{ item.customerServiceName }}
									</div>
								</div>
							</div>
						</el-carousel-item>
					</el-carousel>
				</div>
			</div>
		</div>
		<div class="module11">
			<div class="content">
				<CourseTitle
					title="在线登记"
					contText="请在此留下您的联系方式，我们的专业顾问将会尽快与您联系！"
				>
				</CourseTitle>
				<div class="formBox Between flex-wrap">
					<div class="list flex-center">
						<div class="imgBox display">
							<img
								class="icon_img"
								src="@/assets/img/course/icon_user.png"
								alt=""
							/>
						</div>
						<input
							type="text"
							placeholder="您的姓名"
							v-model="params.userName"
						/>
					</div>
					<div class="list flex-center">
						<div class="imgBox display">
							<img
								class="icon_img"
								src="@/assets/img/course/icon_phone.png"
								alt=""
							/>
						</div>
						<input
							type="text"
							placeholder="您的电话"
							maxlength="11"
							v-model="params.phoneNumber"
						/>
					</div>
					<div class="list flex-center">
						<div class="imgBox display">
							<img
								class="icon_img"
								src="@/assets/img/course/icon_email.png"
								alt=""
							/>
						</div>
						<input type="text" placeholder="您的邮箱" v-model="params.email" />
					</div>
					<div class="list flex-center">
						<div class="imgBox display">
							<img
								class="icon_img"
								src="@/assets/img/course/icon_company.png"
								alt=""
							/>
						</div>
						<input
							type="text"
							placeholder="您的公司"
							v-model="params.companyName"
						/>
					</div>
					<div class="list_textarea flex-center">
						<div class="imgBox display">
							<img
								class="icon_img"
								src="@/assets/img/course/icon_problem.png"
								alt=""
							/>
						</div>
						<textarea
							name=""
							id=""
							cols="30"
							placeholder="您的问题"
							rows="10"
							v-model="params.questionContent"
						></textarea>
					</div>
				</div>
				<div class="submitBtn display pointer" @click="submitBtn">提交</div>
			</div>
		</div>
	</div>
</template>
<script>
import BannerImg from '@/components/bannerImg'
import CoreCourses from '@/components/coreCourses'
import Lecturer from '@/components/lecturer'
import CourseTitle from './components/courseTitle.vue'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
export default {
	components: {
		BannerImg,
		CourseTitle,
		CoreCourses,
		Lecturer,
		Swiper,
		SwiperSlide,
	},
	data() {
		return {
			navBarIndex: 0,
			navTypeIndex: 0,
			safetyTrainingIndex: 0,
			formData: {},
			safetyAccidents: [],
			navBar: [
				{
					id: 1,
					name: '建筑工程',
				},
				{
					id: 2,
					name: '企业生产',
				},
			],
			serviceProcess: [],
			lecturerList: [],
			swiperList: [],
			safetyTrainingList: [],
			params: {
				userName: '',
				phoneNumber: '',
				courseId: '',
				courseType: 5,
				courseName: '专项安全培训课程',
				email: '',
				companyName: '',
				questionContent: '',
			},
			swiperOptions: {
				slidesPerView: 5,
				spaceBetween: 20,
				pagination: {
					el: '.swiper-pagination', //与slot="pagination"处 class 一致
					clickable: true, //轮播按钮支持点击
				},
			},
		}
	},
	mounted() {
		document.documentElement.scrollTop = 0
		this.init()
	},
	methods: {
		init() {
			this.api.findSafetyTrainingInfo().then((res) => {
				if (res.code == 0) {
					res.data.whyStudyImgUrl = res.data.whyStudyImgUrl.split(',')
					this.safetyAccidents =
						res.data.safetySecurityIncidentTypeVOS[0].safetySecurityIncidentVOS
					this.safetyTrainingList = res.data.safetySafetyTrainingVOS[0].children
					this.swiperList =
						this.safetyTrainingList[0].safetyTrainingImgUrl.split(',')
					this.formData = res.data
					this.params.courseId = res.data.id
					this.formData.safetyCustomerServiceVOS = this.carousel(
						this.formData.safetyCustomerServiceVOS,
						5
					)
					this.formData.safetySafetyTrainingVOS.forEach((item) => {
						let a = item.label.slice(2, 4)
						let span = '<span class="colorSpan">' + a + '</span>'
						item.label = item.label.replace(a, span)
					})
				}
			})
		},
		// 轮播图格式化
		carousel(dataList, num) {
			let newDataList = []
			let current = 0
			if (dataList && dataList.length > 0) {
				for (let i = 0; i <= dataList.length - 1; i++) {
					if (i % num !== 0 || i === 0) {
						if (!newDataList[current]) {
							newDataList.push([dataList[i]])
						} else {
							newDataList[current].push(dataList[i])
						}
					} else {
						current++
						newDataList.push([dataList[i]])
					}
				}
			}
			return (dataList = [...newDataList])
		},
		//点击常见安全事故高亮
		changeBtn(item, index) {
			this.navBarIndex = index
			this.safetyAccidents = item.safetySecurityIncidentVOS
		},
		changeType(item, index) {
			this.safetyTrainingIndex = index
			this.navTypeIndex = 0
			this.safetyTrainingList = item.children
			this.swiperList =
				this.safetyTrainingList[0].safetyTrainingImgUrl.split(',')
		},
		navTypeBtn(item, index) {
			this.swiperList = item.safetyTrainingImgUrl.split(',')
			this.navTypeIndex = index
		},
		submitBtn() {
			this.params.courseId = this.formData.id
			var reg = /^1(3|4|5|6|7|8|9)\d{9}$/
			if (this.params.userName == '') {
				return this.$message.error('请输入您的姓名')
			} else if (!reg.test(this.params.phoneNumber)) {
				return this.$message.error('请输入您的电话')
			} else if (this.params.email == '') {
				return this.$message.error('请输入您的邮箱')
			} else if (this.params.companyName == '') {
				return this.$message.error('请输入您的公司')
			} else if (this.params.questionContent == '') {
				return this.$message.error('请输入您的问题')
			}
			this.api.addCourseApply(this.params).then((res) => {
				if (res.code == 0) {
					this.$message.success('提交成功')
					this.params = {
						userName: '',
						phoneNumber: '',
						courseId: '',
						courseType: 5,
						courseName: '专项安全培训课程',
						email: '',
						companyName: '',
						questionContent: '',
					}
				}
			})
		},
	},
}
</script>
<style lang="scss" scoped>
.safetyTraining_page {
	.module1 {
		background: url('../../assets/img/course/bg11.png') no-repeat;
		background-size: 100% 100%;
		height: 911px;
		.carousel {
		}
		.text {
			font-size: 16px;
			font-family: Source Han Sans SC;
			font-weight: 400;
			color: #666666;
			margin-top: -80px;
		}

		.carousel_img {
			width: 880px;
			height: 530px;
			margin: 0 auto;
			margin-top: 50px;
		}
		::v-deep.el-carousel__item {
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;
		}
		::v-deep.el-carousel__container {
			height: 700px;
		}
		::v-deep.el-carousel__button {
			width: 17px;
			height: 17px;
			background: #003c7e;
			border: 1px solid #003c7e;
			border-radius: 50%;
			z-index: 99;
		}
		::v-deep.el-carousel {
			.el-carousel__container {
				.el-carousel__arrow {
					display: none;
				}
			}
		}
	}
	.module2 {
		padding-bottom: 90px;
		.navBar {
			margin-top: 15px;
			.navBarActive {
				background: #ff881e !important;
				color: #fff !important;
			}
			.navBar_list {
				width: 138px;
				height: 46px;
				background: #eeeeee;
				border-radius: 3px;
				font-size: 16px;
				font-family: Microsoft YaHei;
				font-weight: 400;
				color: #333333;
				margin: 0px 9px;
			}
		}
		.safetyAccidents {
			margin-top: 30px;
			.safetyAccidents_list:nth-child(3n + 1) {
				margin-left: 0 !important;
			}
			.safetyAccidents_list {
				margin-bottom: 18px;
				margin-left: 45px;
				.safetyAccidents_list_img {
					width: 370px;
					height: 250px;
				}
				.name {
					width: 370px;
					height: 54px;
					font-size: 18px;
					font-family: Microsoft YaHei;
					font-weight: 400;
					color: #333333;
				}
			}
		}
	}
	.module3 {
		min-height: 860px;
		background: url('../../assets/img/course/bg12.png') no-repeat;
		background-size: 100% 100%;
		.safety_training {
			height: 560px;
			background: white;
			margin-top: 35px;
			border-radius: 5px;
			box-shadow: -1px 0px 6px 0px rgba(0, 0, 0, 0.15);
			.active {
				background: linear-gradient(0deg, #004795, #0b7fff) !important;
				.typeName {
					color: white !important;
					span {
						color: white !important;
					}
				}
			}
			.safety_training_type {
				.list:nth-child(1) {
					border-radius: 5px 5px 0px 0px !important;
				}

				.list {
					width: 298px;
					height: 280px;
					background: #f4f4f4;
					border-radius: 0px 0px 5px 5px;
					.typeName {
						width: 255px;
						font-size: 30px;
						font-family: Microsoft YaHei;
						font-weight: bold;
						color: #333333;
						text-align: center;
					}
				}
			}
			.safety_training_cont {
				margin-left: 23px;
				padding-top: 30px;
				.navType {
					width: 850px;
					.navBarBox {
						width: 150px;
					}
					.navTypeActive {
						background: #0b7fff !important;
						color: #fff !important;
					}
					.list:nth-child(1) {
						margin-left: 0 !important;
					}
					.list {
						width: 156px;
						height: 48px;
						background: #f4f4f4;
						border-radius: 5px;
						box-sizing: border-box;
						font-size: 18px;
						font-family: Microsoft YaHei;
						font-weight: 400;
						color: #333333;
						margin-left: 15px;
					}
				}
				::v-deep.swiper-container {
					width: 100%;
				}
				.carousel_box {
					width: 850px;
					height: 430px;
					margin-top: 20px;
					background: #f3f6f8;
					border-radius: 5px;
					.carousel_img {
						width: 100%;
						height: 100%;
					}
					::v-deep.el-carousel__container {
						height: 430px;
					}

					::v-deep.el-carousel {
						.el-carousel__container {
							.el-carousel__arrow {
								width: 48px;
								height: 48px;
								font-size: 24px;
								background: rgba(51, 51, 51, 0.5);
								display: flex;
								align-items: center;
								justify-content: center;
							}
						}
					}
				}
			}
		}
	}
	.module5 {
		min-height: 510px;
		background: #fafafa;
		.service_process {
			margin-top: 50px;
			.process_list:nth-child(1) {
				.process_list_jt {
					display: none;
				}
			}
			.process_list {
				position: relative;
				.process_list_jt {
					width: 112px;
					height: 12px;
					position: absolute;
					top: 47px;
					right: 172px;
				}
				.process_list_img {
					width: 98px;
					height: 98px;
					background: #003c7e;
					border-radius: 50%;
				}

				.process_list_name {
					font-size: 20px;
					color: #003c7e;
					margin-top: 30px;
				}
				.process_list_introduce {
					width: 150px;
					height: 70px;
					font-size: 14px;
					color: #333333;
					line-height: 24px;
					margin-top: 15px;
					text-align: center;
					overflow: hidden;
					text-overflow: ellipsis;
					display: -webkit-box;
					-webkit-line-clamp: 3;
					-webkit-box-orient: vertical;
				}
			}
		}
	}
	.module7 {
		height: 739px;
	}
	.module8 {
		height: 870px;
		background: url('../../assets/img/course/bg13.png') no-repeat;
		background-size: 100% 100%;
		.icon_bg {
			width: 880px;
			height: 577px;
			margin-top: 63px;
		}
	}
	.module9 {
		min-height: 816px;
		.carousel_box {
			width: 1400px;
			margin: 0 auto;
		}
		::v-deep.el-carousel__container {
			height: 510px;
			display: flex;
			align-items: center;
			justify-content: center;
		}

		::v-deep.el-carousel {
			.el-carousel__item {
				display: flex;
				align-items: center;
				justify-content: center;
			}

			.el-carousel__container {
				.el-carousel__arrow {
					width: 48px;
					height: 48px;
					font-size: 24px;
					background: rgba(51, 51, 51, 0.5);
					display: flex;
					align-items: center;
					justify-content: center;
				}
			}
		}
		::v-deep.el-carousel__indicators {
			display: none;
		}
		.el_carouse_list {
			position: relative;
			.scenario_img {
				width: 1200px;
				height: 500px;
			}
			.scenario_name {
				width: 1200px;
				height: 47px;
				background: #003c7e;
				opacity: 0.8;
				position: absolute;
				left: 0;
				bottom: 0;
				z-index: 99999;
				font-size: 18px;
				font-family: Microsoft YaHei;
				font-weight: 400;
				color: #ffffff;
			}
		}
	}
	.module10 {
		height: 488px;
		background: #fafafa;
		.serve_customer {
			margin-top: 28px;
			.serve_customer_list:nth-child(5n + 1) {
				margin-left: 0 !important;
			}
			.serve_customer_list {
				margin-left: 49px;
				img {
					width: 200px;
					height: 180px;
				}
				.serve_customer_name {
					width: 200px;
					font-size: 16px;
					font-family: Microsoft YaHei;
					font-weight: 400;
					color: #333333;
					line-height: 16px;
					padding: 20px 0px;
					box-sizing: border-box;
				}
			}
		}
		::v-deep.el-carousel {
			.el-carousel__item {
				display: flex;
				align-items: center;
				justify-content: center;
			}

			.el-carousel__container {
				.el-carousel__arrow {
					width: 48px;
					height: 48px;
					font-size: 24px;
					background: rgba(51, 51, 51, 0.5);
					display: flex;
					align-items: center;
					justify-content: center;
				}
			}
		}
	}
	.module11 {
		height: 688px;
		.formBox {
			width: 1040px;
			margin: 0 auto;
			margin-top: 50px;
			.list {
				width: 500px;
				height: 48px;
				background: #ffffff;
				border: 1px solid #dedede;
				margin-bottom: 30px;
				.imgBox {
					width: 50px;
					height: 46px;
					background: #dedede;
					.icon_img {
						width: 25px;
						height: 25px;
					}
				}

				input {
					width: 430px;
					height: 42px;
					font-size: 16px;
					padding-left: 20px;
				}
			}
			.list_textarea {
				width: 1039px;
				height: 140px;
				background: #ffffff;
				border: 1px solid #dedede;
				.imgBox {
					width: 50px;
					height: 138px;
					background: #dedede;
					img {
						width: 25px;
						height: 25px;
					}
				}
				textarea {
					width: 950px;
					height: 138px;
					font-size: 16px;
					padding: 20px;
					box-sizing: border-box;
				}
			}
		}
		.submitBtn {
			width: 200px;
			height: 48px;
			background: #ff881e;
			font-size: 18px;
			font-family: Microsoft YaHei;
			font-weight: 400;
			color: #ffffff;
			margin: 0 auto;
			margin-top: 40px;
		}
	}
}
</style>
<style>
.colorSpan {
	color: #004796 !important;
}
.active .colorSpan {
	color: #fff !important;
}
</style>